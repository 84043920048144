<template>
  <div id="benefits" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">PRESTACIONES</v-row
      ><v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="listar()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <router-link to="/addBenefits" id="nuevaPrestacion">
              <button class="breakSearch botonAmarillo">
                Alta de Prestación
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="benefits"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Prestaciones por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.Id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombrePrestacion }}</td>
                  <td class="text-sm-center">
                    <span v-if="item.tipoPrestacion == 1">Ley</span>
                    <span v-if="item.tipoPrestacion == 2">Adicionales</span>
                  </td>
                  <td class="text-sm-center">
                    <span v-if="item.aplicaSDI == true">Sí</span>
                    <span v-if="item.aplicaSDI == false">No</span>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Prestación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Editar item-->
    <v-dialog v-model="edit" width="370">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <!--NOMBRE DE AREA-->
          <v-row class="mt-2">
            <div class="form-group mb-5">
              <label for="area" class="ml-2">Nombre de Prestación</label>
              <input
                type="text"
                name="area"
                id="area"
                placeholder="Obligatorio"
                class="ml-2 inputs"
                autocomplete="false"
                required
                maxlength="50"
                v-model="nombrePrestacion"
                @keypress="alfaNumerico($event)"
              />
            </div>
          </v-row>
          <!--tipo de prestación-->
          <v-row>
            <div class="form-group mb-3">
              <label for="tipo" class="ml-2 mr-1">Tipo de Prestacion</label>
              <select
                name="tipo"
                id="tipo"
                class="ml-7 mt-n6"
                style="width: 170px !important"
                v-model="tipo"
              >
                <option selected disabled value="0">Seleccione...</option>
                <option value="1">Ley</option>
                <option value="2">Adicionales</option>
              </select>
            </div> </v-row
          ><!--aplica a sdi-->
          <v-row>
            <div class="form-group mb-3">
              <label for="sdi" class="ml-2 mr-1">Aplica para el SDI</label>
              <select
                name="sdi"
                id="sdi"
                class="ml-9 mt-n6"
                style="width: 170px !important"
                v-model="sdi"
              >
                <option selected disabled value="0">Seleccione...</option>
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div> </v-row
          ><!--como aplica-->
          <v-row>
            <div class="form-group mb-2">
              <label for="sdi" class="ml-2 mr-10">Como aplica</label>
              <select
                name="sdi"
                id="sdi"
                class="mt-n6 ml-8"
                style="width: 170px !important"
                v-model="aplica"
              >
                <option selected disabled value="0">Seleccione...</option>
                <option value="1">Monto</option>
                <option value="2">Porcentaje</option>
              </select>
            </div>
          </v-row>
          <v-row v-if="aplica == 1">
            <div class="form-group">
              <label for="monto" class="ml-5 mr-12">Monto</label>
              <input
                type="text"
                name="monto"
                id="monto"
                placeholder="Obligatorio"
                class="inputs ml-12"
                autocomplete="false"
                required
                maxlength="7"
                v-model="monto"
                @keypress="isNumberPoint($event)"
              />
            </div>
            <br />
            <label v-if="vacio" style="color: red"
              >*Se deben especificar todos los campos</label
            >
          </v-row>
          <v-row v-if="aplica == 2">
            <div class="form-group">
              <label for="porcentaje" class="ml-2 mr-10">Porcentaje</label>
              <input
                type="text"
                name="porcentaje"
                id="porcentaje"
                placeholder="Obligatorio"
                class="inputs ml-11"
                autocomplete="false"
                required
                maxlength="5"
                v-model="porcentaje"
                @keypress="isNumberPoint($event)"
              />%
              <br />
              <label v-if="vacio" style="color: red"
                >*Se deben especificar todos los campos</label
              >
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              edit = false;
              vacio = false;
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="400px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Prestación</v-card-title
        >
        <v-card-text id="info">
          <div class="form-group mt-5">
            <label>Razón social:</label>
            {{ benefitSelect.empresa }}
          </div>
          <div class="form-group">
            <label>Id de Prestación:</label>
            {{ benefitSelect.id }}
          </div>
          <div class="form-group">
            <label>Nombre de Prestación:</label>
            {{ benefitSelect.nombrePrestacion }}
          </div>
          <div class="form-group">
            <label>Tipo de Prestación:</label>
            <span v-if="benefitSelect.tipoPrestacion == 1">Ley</span>
            <span v-if="benefitSelect.tipoPrestacion == 2">Adicionales</span>
          </div>
          <div class="form-group">
            <label>Aplica a SDI:</label>
            <span v-if="benefitSelect.aplicaSDI == true">Sí</span>
            <span v-if="benefitSelect.aplicaSDI == false">No</span>
          </div>
          <div class="form-group">
            <label>Como aplica :</label>
            <span v-if="benefitSelect.tipoAplicacion == 1">Monto</span>
            <span v-if="benefitSelect.tipoAplicacion == 2">Porcentaje</span>
          </div>
          <div class="form-group">
            <label>Como aplica :</label>
            {{ benefitSelect.montoPorcentaje }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de prestación",
          align: "center",
          value: "nombrePrestacion",
        },
        {
          text: "Tipo de Prestación",
          align: "center",
          value: "tipo",
        },
        {
          text: "Aplica SDI",
          align: "center",
          value: "sdi",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      benefits: [],
      benefitSelect: [],
      respuesta: "",
      advice: false,
      confirmation: false,
      edit: false,
      vacio: false,
      porcentaje: 0,
      monto: 0,
      aplica: 0,
      nombrePrestacion: "",
      id: 0,
      sdi: 0,
      tipo: 0,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
    };
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    isNumberPoint: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 46) {
        return true;
      }
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion: function () {
      this.errors = [];
      if (this.nombrePrestacion == "" || this.nombrePrestacion == " ") {
        this.errors.push("Se debe especificar el nombre de la prestación.");
        console.log("nombre pres");
      }
      if (this.tipo == 0) {
        this.errors.push("Se debe seleccionar el tipo de prestación.");
        console.log("tipo pres");
      }
      if (this.sdi === 0) {
        this.errors.push("Se debe especificar la aplicación a SDI.");
        console.log("sdi pres");
      }
      if (this.aplica == 0) {
        this.errors.push("Se debe especificar como aplica.");
        console.log("como aplica pres");
      }
      if (this.aplica == 1 && this.monto == 0) {
        this.errors.push("Se debe especificar el monto.");
        console.log("monto pres");
      }
      if (this.aplica == 2 && this.porcentaje == 0) {
        this.errors.push("Se debe especificar el porcentaje.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },
    editar() {
      //console.log("El id para la api es" + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/prestaciones/" + this.id,
          {
            id: this.id,
            empresaId: this.empresaId,
            nombrePrestacion: this.nombrePrestacion,
            tipoPrestacion: parseInt(this.tipo),
            aplicaSDI: this.sdi,
            tipoAplicacion: this.aplica,
            montoPorcentaje:
              this.aplica == "1"
                ? parseFloat(this.monto)
                : parseFloat(this.porcentaje),
            //porcentaje: this.porcentaje,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.nombrePrestacion = "";
          this.monto = 0;
          this.porcentaje = 0;
          this.tipo = 0;
          this.aplica = 0;
          this.sdi = 0;
          this.respuesta = "El elemento fue actualizado con éxito";
          this.listar();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.nombrePrestacion = "";
            this.monto = 0;
            this.porcentaje = 0;
            this.tipo = 0;
            this.aplica = 0;
            this.sdi = 0;
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = false;
          }
        });
    },
    editItem(item) {
      this.id = item.id;
      this.empresaId = item.empresaId;
      this.nombrePrestacion = item.nombrePrestacion;
      this.tipo = item.tipoPrestacion;
      this.sdi = item.aplicaSDI;
      this.aplica = item.tipoAplicacion;
      this.monto = item.montoPorcentaje;
      this.porcentaje = item.montoPorcentaje;
      this.edit = true;
    },
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar la prestación " +
        item.nombrePrestacion +
        " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/prestaciones/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.id = "";
          this.respuesta = "La prestación fue eliminada con éxito.";
          this.show = false;
          this.confirmation = true;
          this.listar();
        })
        .catch((e) => {
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "La prestación no fue eliminada.";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      console.log(item);
      this.benefitSelect = item;
      var empresa = this.enterprises.find(
        (empresa) => empresa.id === this.benefitSelect.empresaId
      );
      this.benefitSelect.empresa = empresa.razonSocial;
      this.dialog = true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/prestaciones/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.benefits = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpresas() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.obtenerEmpresas();
    this.listar();
  },
};
</script>